<template>
  <v-btn
    :color="color"
    @click.stop.prevent="handleConvertToOwner"
    small
  >
  {{name}}
  </v-btn>
</template>
<script>
const mainWorkspace = 'f0550a87-3358-4876-bf24-0ac33e9a93cf'

import api from '@/services/api'
export default {
  props: {
    workspaceID: {
      type: String,
      required: true,
    },
    cw: {
      type: Object,
      default: null,
    },
    editedID: {
      type: String,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    onUpdate: {
      type: Function,
      required: true,
    },
  },
  computed: {
    color () {
      if (this.cw.ID === this.workspaceID) return 'primary'
      if (this.cw.ID === mainWorkspace) return '#CCCCCC'
      if (this.cw.ID) return 'red'
      return '#CCCCCC'
    },
    name () {
      if (this.cw.ID === this.workspaceID) return this.$t('turismo.assigned', this.locale)
      if (this.cw.ID === mainWorkspace) return this.$t('turismo.convertToOwner', this.locale)
      if (this.cw.ID) return this.$t(`turismo.assignedTo`, this.locale).concat(' ', this.cw.Name)
      return this.$t('turismo.convertToOwner', this.locale)
    },
  },
  methods: {
    handleConvertToOwner () {
      const body = {
        cw: this.cw.ID === this.workspaceID ? mainWorkspace : this.workspaceID
      }
      api.updateItem('turismo', `v1/private/menu-content/`, this.editedID.concat('/cw'), body)
        .then(() => {
          this.onUpdate(this.editedID)
        })
    },
  },
}
</script>

